import React, { MouseEventHandler, forwardRef } from 'react'
import { BiArrowToRight } from 'react-icons/bi'
import { MdAdd, MdOutlineDelete } from 'react-icons/md'

import Reset from '../../assets/icons/advanced-reset.svg'
import Advanced from '../../assets/icons/advanced.svg'
import AiRegenerate from '../../assets/icons/ai-regenerate.svg'
import AISuggestionWhite from '../../assets/icons/ai-suggestion-white.svg'
import AISuggestion from '../../assets/icons/ai-suggestion.svg'
import ArrowBackIcon from '../../assets/icons/arrow-back2.svg'
import Check from '../../assets/icons/check.svg'
import Clear from '../../assets/icons/clear.svg'
import CloseIcon from '../../assets/icons/close.svg'
import DrumsIcon from '../../assets/icons/drums.svg'
import Drag from '../../assets/icons/handle.svg'
import Random from '../../assets/icons/icon-random.svg'
import PianoIcon from '../../assets/icons/piano.svg'
import PortalButtonChords from '../../assets/icons/portal-button-chords.svg'
import PortalButtonDrums from '../../assets/icons/portal-button-drums.svg'
import styles from './IconButton.module.scss'

const iconNames = [
  'arrow-back',
  'subtract',
  'close',
  'add',
  'extend',
  'ai-suggestion',
  'random',
  'portal-button-CHORDS',
  'portal-button-MELODY',
  'portal-button-DRUMS',
  'drag',
  'ai-suggestion-white',
  'drums',
  'piano',
  'advanced',
  'reset',
  'check',
  'clear',
  'ai-regenerate',
] as const
type IconType = typeof iconNames[number]

const iconSizes = ['small', 'medium', 'large'] as const
type IconSize = typeof iconSizes[number]

type Props = {
  className?: string
  enabled?: boolean
  icon?: IconType
  size?: IconSize
  isHoverWhite?: boolean
  order?: number
  color?: string | undefined
  onClick?: (e?: any) => void
  onMouseDown?: MouseEventHandler<HTMLDivElement>
  cursoring?: boolean
  onDrag?: (e?: any) => void
}

const GetIcon = (icon?: IconType, color?: string) => {
  switch (icon) {
    case 'close':
      return <CloseIcon style={color ? { color } : {}} className={styles.icon} />
    case 'arrow-back':
      return <ArrowBackIcon style={color ? { color } : {}} className={styles.icon} />
    case 'add':
      return <MdAdd style={color ? { color } : {}} className={styles.icon} />
    case 'subtract':
      return <MdOutlineDelete style={color ? { color } : {}} className={styles.icon} />
    case 'extend':
      return <BiArrowToRight style={color ? { color } : {}} className={styles.icon} />
    case 'ai-suggestion':
      return <AISuggestion style={color ? { color } : {}} className={styles.icon} />
    case 'ai-suggestion-white':
      return <AISuggestionWhite style={color ? { color } : {}} className={styles.icon} />
    case 'random':
      return <Random style={color ? { color } : {}} className={styles.icon} />
    case 'portal-button-CHORDS':
      return <PortalButtonChords />
    case 'portal-button-DRUMS':
      return <PortalButtonDrums />
    case 'drag':
      return <Drag />
    case 'drums':
      return <DrumsIcon />
    case 'piano':
      return <PianoIcon />
    case 'advanced':
      return <Advanced />
    case 'reset':
      return <Reset />
    case 'check':
      return <Check />
    case 'clear':
      return <Clear />
    case 'ai-regenerate':
      return <AiRegenerate />
    default:
      return
  }
}

const IconButton = forwardRef(
  (
    {
      className,
      size,
      isHoverWhite,
      enabled,
      cursoring,
      order,
      onClick,
      onMouseDown,
      onDrag,
      icon,
      color,
      ...props
    }: Props,
    ref: any,
  ) => {
    const classes = [styles.container, className]
    if (size === 'small') {
      classes.push(styles.small)
    } else if (size === 'medium') {
      classes.push(styles.medium)
    } else if (size === 'large') {
      classes.push(styles.large)
    }
    if (isHoverWhite) {
      classes.push(styles.isHoverWhite)
    } else {
      classes.push(styles.isHoverBlack)
    }
    if (enabled) {
      classes.push(styles.enabled)
    }
    if (cursoring) {
      classes.push(styles.cursoring)
    }

    return (
      <div
        {...props}
        ref={ref}
        style={{ order: order }}
        onClick={enabled ? onClick : () => void 0}
        onMouseDown={enabled ? onMouseDown : () => void 0}
        className={classes.join(' ')}
        onDrag={onDrag}
      >
        {GetIcon(icon, color)}
      </div>
    )
  },
)

IconButton.defaultProps = {
  className: '',
  enabled: true,
  icon: 'close',
  size: 'small',
  color: undefined,
  cursoring: true,
  isHoverWhite: true,
  order: 0,
  onClick: () => void 0,
  onDrag: () => void 0,
}

export default IconButton
