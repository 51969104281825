import classNames from 'classnames'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'

import stylesCanvas from '../canvas/ChordCanvas.module.scss'
import styles from './CenterModal.module.scss'
import IconButton from './IconButton'

const modalSizes = ['tiny', 'smallest', 'small', 'medium', 'large', 'fullPage'] as const
type ModalSize = typeof modalSizes[number]

const modalTints = ['light', 'dark', 'none'] as const
type ModalTint = typeof modalTints[number]

type Props = {
  className?: string
  modalClassName?: string
  withCloseIcon?: boolean
  closeIcon?: string
  closeIconClassName?: string
  size?: ModalSize
  tint?: ModalTint
  theme?: ModalTint
  hideClose?: boolean
  closable?: boolean
  children: ReactNode
  title?: string
  visible?: boolean
  onCancel?: (iconClick?: boolean) => void
  loginModal?: boolean
  canvasMode?: boolean
  zIndex?: number
  disableClickOutsideHook?: boolean
  maxWidth?: number
}

const CenterModal: React.FC<Props> = ({
  closable = true,
  loginModal = false,
  canvasMode = false,
  disableClickOutsideHook = true,
  maxWidth,
  ...props
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [parentContainer, setParentContainer] = useState<HTMLDivElement | null>(null)

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      props.onCancel && props.onCancel()
    }
  }

  useEffect(() => {
    if (props.visible) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [props.visible])
  useEffect(() => {
    const el = document.createElement('div')
    setParentContainer(el)
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [])

  const classes = [styles.wrap]

  const maskClasses = [styles.mask]
  props.tint == 'light' && maskClasses.push(styles.light)
  props.tint == 'dark' && maskClasses.push(styles.dark)

  const modalClasses = [styles.modal]
  props.size == 'tiny' && modalClasses.push(styles.tiny)
  props.size == 'small' && modalClasses.push(styles.small)
  props.size == 'smallest' && modalClasses.push(styles.smallest)
  props.size == 'medium' && modalClasses.push(styles.medium)
  props.size == 'large' && modalClasses.push(styles.large)
  props.size == 'fullPage' && modalClasses.push(styles.fullPage)
  canvasMode && modalClasses.push(styles.canvasMode)

  const contentClasses = [styles.modalContent, props.className, 'center-modal']
  props.theme == 'light' && contentClasses.push(styles.light)
  props.theme == 'dark' && contentClasses.push(styles.dark)

  const closeIconClasses = [styles.closeIcon, props.closeIconClassName]
  props.withCloseIcon && closeIconClasses.push(styles.withCloseIcon)
  props.theme == 'light' && closeIconClasses.push(styles.dark)
  props.theme == 'dark' && closeIconClasses.push(styles.light)

  useEffect(() => {
    if (modalRef && modalRef.current && props.visible) {
      modalRef.current.focus()
    }
  }, [props.visible])

  return (
    <Transition in={props.visible} timeout={100} unmountOnExit nodeRef={modalRef}>
      {(status) => (
        <>
          {parentContainer
            ? createPortal(
                <div
                  className={`${styles.wrap} center-modal`}
                  style={{ zIndex: props.zIndex || 10 }}
                  data-totally-disable-click-outside-hook={disableClickOutsideHook}
                >
                  <div
                    tabIndex={0}
                    ref={modalRef}
                    className={classNames(classes.join(' '), {
                      [styles.modalActive]: status === 'entering',
                      [styles.modalDone]: status === 'entered',
                      [styles.modalExitActive]: status === 'exiting',
                      [styles.modalExitDone]: status === 'exited',
                    })}
                    role='dialog'
                  >
                    <div
                      className={classNames(maskClasses, { [styles.canvasMode]: canvasMode })}
                      onClick={() => {
                        if (closable) props.onCancel && props.onCancel()
                      }}
                    />
                    <div
                      className={classNames(
                        modalClasses,
                        { [styles.loginModal]: loginModal },
                        { [styles.canvasMode]: canvasMode },
                        { [styles.canvasMode]: canvasMode },
                        props.modalClassName,
                      )}
                      style={{ maxWidth: maxWidth || '100%' }}
                      role='document'
                    >
                      <div className={classNames(contentClasses)}>
                        {props.hideClose ? null : (
                          <IconButton
                            className={classNames(
                              closeIconClasses,
                              { [styles.canvasMode]: canvasMode },
                              { [stylesCanvas.noise]: canvasMode },
                            )}
                            // @ts-ignore

                            icon={props.closeIcon || 'close'}
                            isHoverWhite={props.theme !== 'light'}
                            onClick={() => props.onCancel && props.onCancel(true)}
                          />
                        )}
                        {props.title && (
                          <div className={styles.modalHead}>
                            <div className={styles.modalTitle}>{props.title}</div>
                          </div>
                        )}
                        <div className={styles.modalBody}>{props.children}</div>
                      </div>
                    </div>
                  </div>
                </div>,
                parentContainer,
              )
            : null}
        </>
      )}
    </Transition>
  )
}

CenterModal.defaultProps = {
  className: '',
  withCloseIcon: true,
  size: 'small',
  tint: 'none',
  title: '',
  visible: false,
  onCancel: () => void 0,
}

export default CenterModal
